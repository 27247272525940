const sizes = {
  container: {
    sm: '40rem',
    md: '48rem',
    lg: '71.25rem',
    xl: '80rem',
    '2xl': '90rem',
  },
}
export default sizes
