
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import {ChakraProvider, createStandaloneToast} from '@chakra-ui/react'
import {ToastContainer as LibToastContainer} from '@coteries/react/ui'
import useTranslation from 'next-translate/useTranslation'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import customTheme from '../styles/index'
import '../styles/styles.css'
import {AuthProvider} from '../utils/AuthContext'

function MyApp({Component, pageProps}: AppProps) {
  const {t} = useTranslation()

  const {ToastContainer} = createStandaloneToast()

  return (
    <AuthProvider>
      <ChakraProvider theme={customTheme}>
        <Head>
          <link href='/favicon.ico' />
          <title>{t('common:meta_title_default')}</title>
        </Head>
        <Component {...pageProps} />
        <ToastContainer />
        <LibToastContainer />
      </ChakraProvider>
    </AuthProvider>
  )
}

// noinspection JSUnusedGlobalSymbols
const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  