const styles = {
  global: {
    h1: {
      fontFamily: 'heading',
      fontSize: ['title1M', 'title1M', 'title1M', 'title1'],
      fontWeight: 'bold',
      letterSpacing: '0.125rem',
    },
    h2: {
      fontFamily: 'heading',
      fontSize: ['title2M', 'title2M', 'title2M', 'title2'],
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: 'heading',
      fontSize: ['title3M', 'title3M', 'title3M', 'title3'],
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'heading',
      fontSize: ['title4M', 'title4M', 'title4M', 'title4'],
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'heading',
      fontSize: ['title5M', 'title5M', 'title5M', 'title5'],
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: 'heading',
      fontSize: ['title6M', 'title6M', 'title6M', 'title6'],
      fontWeight: 'bold',
    },
    p: {
      fontFamily: 'body',
      fontSize: 'regular',
    },
  },
}

export default styles
