const fontSizes = {
  //Titles
  title1: '3.5rem',
  title1M: '2rem',
  title2: '2.75rem',
  title2M: '1.5rem',
  title3: '2.25rem',
  title3M: '1rem',
  title4: '1.75rem',
  title4M: '1.25rem',
  title5: '1.5rem',
  title5M: '1.25rem',
  title6: '1.25rem',
  title6M: '1rem',

  //Default font sizes
  small: '0.75rem',
  smallM: '0.75rem',
  regular: '1rem',
  regularM: '0.75rem',
  large: '1rem',
  largeM: '1rem',

  //Special styles
  navLink: '0.9rem',
  navLinkM: '0.9rem',
  bigNumber: '3rem',
  bigNumberM: '3rem',
  socialNumberDetails: '0.75rem',
  socialNumberDetailsM: '0.75rem',
  socialNumberCard: '0.75rem',
  socialNumberCardM: '0.75rem',
  label: '0.5rem',
  labelM: '0.5rem',
  searchInput: '1.5rem',
  searchInputM: '1.25rem',
  searchTitle: '1rem',
  searchTitleM: '1rem',
  footerLinks: '1rem',
  footerLinksM: '0.75rem',
  heroSubtitle: '1.25rem',
  heroSubtitleM: '1rem',
}

export default fontSizes
