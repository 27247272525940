const colors = {
  brand: {
    primary: '#00B880',
    accentuation: '#009B6C',
    lightGreen: '#B3EAD9',
    superLightGreen: '#E5F8F3',
    background: '#F3FAF9',
    textColor: '#3C3E40',
    grey: '#81868F',
    lightGrey: '#C5CBD3',
    superLightGrey: '#F7F9FC',
    red: '#FF0000',
    white: '#FFF',
    black: '#000',
  },
}
export default colors
